@tailwind base;
@tailwind components;
@tailwind utilities;

/* PACKAGE STYLES */
@import "swiper/css";
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/autoplay";
@import "swiper/css/autoplay";
@import "swiper/css/effect-fade";
@import "react-phone-number-input/style.css";
@import "react-toastify/dist/ReactToastify.css";

/* FONTS */
@font-face {
  font-family: "ProximaNova-Thin";
  src: url("../assets/fonts/ProximaNova-Thin.woff");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("../assets/fonts/ProximaNova-Light.woff");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../assets/fonts/ProximaNova-Regular.woff");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../assets/fonts/ProximaNova-Semibold.woff");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../assets/fonts/ProximaNova-Bold.woff");
}

@font-face {
  font-family: "ProximaNova-Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabld.woff");
}

@font-face {
  font-family: "InterTight-Black";
  src: url("../assets/fonts/InterTight-Black.ttf");
}

@font-face {
  font-family: "InterTight-Extrabold";
  src: url("../assets/fonts/InterTight-ExtraBold.ttf");
}

@font-face {
  font-family: "InterTight-Bold";
  src: url("../assets/fonts/InterTight-Bold.ttf");
}

@font-face {
  font-family: "InterTight-Semibold";
  src: url("../assets/fonts/InterTight-SemiBold.ttf");
}

@font-face {
  font-family: "InterTight-Medium";
  src: url("../assets/fonts/InterTight-Medium.ttf");
}

@font-face {
  font-family: "InterTight-Regular";
  src: url("../assets/fonts/InterTight-Regular.ttf");
}

.container {
  max-width: 155vh !important;
}

.container-full-width {
  max-width: 100%;
}

.shadow-header {
  box-shadow: 0px 3px 5px rgba(57, 63, 72, 0.2);
}

.shadow-header.slide-in {
  animation: slideIn 0.5s ease-in;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* CUSTOM UNDERLINE */
.custom-underline {
  position: relative;
}

.custom-underline::after {
  content: "";
  position: absolute;
  background-color: #000;
  height: 0.2vh;
  width: 0;
  left: 0;
  bottom: -3px;
  transition: 0.4s;
}

.custom-underline:hover:after {
  width: 100%;
}

/* WEBSITE SCROLLBAR */
body::-webkit-scrollbar {
  display: none;
}

/* PHONE NUMBER INPUT */
.PhoneInputInput {
  padding-left: 10px;
  font-size: 1.5vh;
}

.PhoneInputInput:focus {
  outline: none;
}

.PhoneInputInput::placeholder {
  font-family: "ProximaNova-Regular", sans-serif;
}

/* REACT SELECT */
.custom__control {
  border: 1px solid #e5e5e5 !important;
  border-radius: 8px !important;
  padding: 0.5vh 1vh;
}

.custom__control .custom__value-container .custom__placeholder {
  font-family: "ProximaNova-Regular", sans-serif;
  font-size: 1.5vh;
  color: rgba(0, 0, 0, 0.5);
}

.custom__control .custom__value-container .custom__single-value {
  font-family: "ProximaNova-Semibold", sans-serif;
  font-size: 1.5vh;
}

.custom__control .custom__indicators .custom__indicator-separator {
  display: none;
}

.custom__menu {
  font-family: "ProximaNova-Regular", sans-serif;
  font-size: 1.5vh;
}

.custom__menu .custom__option--is-focused {
  background-color: #d1b12a;
}

/* MEDIA QUERY */
@media (max-width: 575px) {
  .container {
    padding: 0vh 1.5vh;
  }
}

@media (min-width: 960px) {
  .container {
    padding: 0vh 1.5vh;
  }
}

/* CUSTOM SHADOW */
.shadow-inner {
  --tw-shadow: inset 0px 0px 4px 4px rgb(0 0 0 / 0.05) !important;
  --tw-shadow-colored: inset 0px 0px 4px 4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.Toastify__toast-body {
  font-family: "ProximaNova-Semibold", sans-serif !important;
  font-size: 1.7vh;
}

.bg-banner {
  background-image: url("../assets/images/main-banner.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}

@media (max-width: 575px) {
  .bg-banner {
    background-image: none;
  }
}
